// use static-path to generate type-safe paths
// https://github.com/garybernhardt/static-path
//
// FYI that this file is processed by bin/codegen-paths.ts
// to generate config/client_routes.json

import { path } from "static-path"

export const rootPath = path("/")

export const signInPath = path("/sign-in")
export const signInTokenPath = path("/users/sign_in/:sessionId")
export const signOutPath = path("/users/sign_out")
export const signUpPath = path("/sign-up")

export const companiesPath = path("/companies")
export const creatorBrandsEditPath = path(
  "/creator-brands/:creatorBrandId/edit"
)
export const creatorBrandsPath = path("/creator-brands")
export const noCompaniesPath = path("/no-companies")
export const profilePath = path("/profile")
export const usersDetailPath = path("/users/:userId")
export const usersEditPath = path("/users/:userId/edit")
export const usersInvitePath = path("/users/invite")
export const usersPath = path("/users")
export const dashboardPath = path("/dashboard")

export const campaignsPath = path("/campaigns")

export const campaignDetailPath = path("/campaigns/:campaignId")
export const campaignDetailGoalsPath = path("/campaigns/:campaignId/goals")
export const campaignDetailMetricsPath = path("/campaigns/:campaignId/metrics")
export const campaignDetailUserPath = path(
  "/campaigns/:campaignId/users/:userId"
)

export const campaignDeliverablePath = path(
  "/campaigns/:campaignId/deliverables/:deliverableId"
)
export const campaignDeliverableScaffoldAdPath = path(
  "/campaigns/:campaignId/deliverables/:deliverableId/scaffold"
)
export const campaignDeliverableEditMetricsPath = path(
  "/campaigns/:campaignId/deliverables/:deliverableId/metrics/edit"
)
export const campaignDeliverableHistoryPath = path(
  "/campaigns/:campaignId/deliverables/:deliverableId/history"
)
export const campaignDeliverableFeedbackPath = path(
  "/campaigns/:campaignId/deliverables/:deliverableId/feedback"
)
export const campaignDeliverableUserPath = path(
  "/campaigns/:campaignId/deliverables/:deliverableId/users/:userId"
)

export const companyCampaignsPath = path("/:slug/campaigns")

export const companyPath = path("/:slug")
export const companyProfilePath = path("/:slug/profile")
export const companyProfileUserPath = path("/:slug/profile/users/:userId")
export const companyProfileEditPath = path("/:slug/profile/edit")
export const companyClientsPath = path("/:slug/clients")
export const companyContentLibraryPath = path("/:slug/content-library")
export const companyContentLibraryUploadPath = path(
  "/:slug/content-library/upload"
)
export const companyContentLibraryWorkweekUploadPath = path(
  "/:slug/content-library/workweek/upload"
)
export const companyContentReferenceDetailPath = path(
  "/:slug/content-library/:campaignDeliverableId"
)
export const companyContentReferenceEditMetricsPath = path(
  "/:slug/content-library/:campaignDeliverableId/metrics/edit"
)
export const companyCreativesPath = path("/:slug/creatives")
export const companyDashboardPath = path("/:slug/dashboard")
export const companyMessagesPath = path("/:slug/messages")
export const companyProductsPath = path("/:slug/products")
export const companyProductsDetailPath = path("/:slug/products/:productId")
export const companyProductSnapshotPath = path(
  "/:slug/products/:productId/snapshot/:snapshotId"
)
export const companyProductsDetailUploadPath = path(
  "/:slug/products/:productId/upload"
)
export const companyProductsEditPath = path("/:slug/products/:productId/edit")
export const companyProductsEditUploadPath = path(
  "/:slug/products/:productId/edit/upload"
)
export const newCompanyProductsPath = path("/:slug/products/new")
export const companyReportsPath = path("/:slug/reports")
export const companyUsersPath = path("/:slug/users")
export const companyUsersInvitePath = path("/:slug/users/invite")
export const companyUsersDetailPath = path("/:slug/users/:userId")
export const companyUsersEditPath = path("/:slug/users/:userId/edit")

export const serverTokenConfirmationPath = path("/users/sign_in/:id")

export const messagesPath = path("/messages")
export const dmPath = path("/messages/dm/:otherUserId")
export const roomPath = path("/messages/group/:roomId")

export const postPath = path("/posts/:postId")
export const editPostPath = path("/posts/:postId/edit")

/** @deprecated alias of usersDetailPath */
export const userProfilePath = usersDetailPath
