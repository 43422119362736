import { useQuery } from "@apollo/client"
import { useParams } from "react-router-dom"
import { Params } from "static-path"
import { gql } from "~/__generated__"
import { formatDate } from "~/common/date-formatting"
import { gqlMatchOptional } from "~/common/gql-match"
import { campaignDeliverablePath } from "~/common/paths"
import { Link } from "~/ui/link"
import { Pane, PaneBody } from "~/ui/pane"
import Text from "~/ui/typography"

gql(/* GraphQL */ `
  fragment StatusLog on CampaignDeliverableStatusLog {
    id
    oldStatusName
    newStatusName
    createdAt
    userName
  }
`)

const query = gql(/* GraphQL */ `
  query CampaignDeliverableStatusLogsQuery($campaignDeliverableId: ID!) {
    node(id: $campaignDeliverableId) {
      __typename
      ... on CampaignDeliverable {
        id
        statusLogs(first: 100) {
          edges {
            node {
              ...StatusLog
            }
          }
        }
      }
    }
  }
`)

export const CampaignDeliverableHistoryPane = () => {
  const params = useParams() as Params<typeof campaignDeliverablePath.pattern>

  const queryResult = useQuery(query, {
    variables: {
      campaignDeliverableId: params.deliverableId,
    },
  })

  const deliverable = gqlMatchOptional(
    queryResult.data?.node,
    "CampaignDeliverable"
  )

  const statusLogs = deliverable?.statusLogs?.edges.map((edge) => edge.node)

  return (
    <Pane>
      <PaneBody className="pt-4 flex flex-col border-b">
        <Link
          to={campaignDeliverablePath({
            campaignId: params.campaignId,
            deliverableId: params.deliverableId,
          })}
          variant="close-pane"
          className="mb-2"
        >
          Close
        </Link>
        <h2 className="font-medium text-lg">Status History</h2>
      </PaneBody>
      <PaneBody className="pt-4 flex flex-col gap-8">
        {statusLogs && statusLogs.length > 0 && (
          <div className="grid gap-2">
            {statusLogs.map((log) => (
              <div
                key={log.id}
                className="rounded-lg bg-gray-f9 p-4 leading-3 space-y-2"
              >
                <Text variant="body-12">
                  <span className="font-bold">{log.userName}</span> updated
                  status from{" "}
                  <span className="font-bold">{log.oldStatusName}</span> to{" "}
                  <span className="font-bold">{log.newStatusName}</span>
                </Text>
                <div>
                  <Text variant="body-10" className="text-gray-400">
                    {formatDate(new Date(log.createdAt), "MMMM dd, yyyy - pp")}
                  </Text>
                </div>
              </div>
            ))}
          </div>
        )}
        <div className="hidden only:flex justify-center items-center">
          <Text variant="body-12" className="text-gray-400">
            No status history
          </Text>
        </div>
      </PaneBody>
    </Pane>
  )
}
