import clsx from "clsx"
import { ReactNode, useEffect } from "react"
import {
  Link,
  Outlet,
  ScrollRestoration,
  useLocation,
  useNavigate,
} from "react-router-dom"
import { useViewer, useViewerMaybe } from "~/auth/viewer-context"
import * as paths from "~/common/paths"
import { useCompanyMaybe } from "~/companies/company-context"
import CampaignsIcon from "~/images/icons/campaigns.svg?react"
import ClientsIcon from "~/images/icons/clients.svg?react"
import CompaniesIcon from "~/images/icons/companies.svg?react"
import CompanyProfileIcon from "~/images/icons/company-profile.svg?react"
import CompanyUsersIcon from "~/images/icons/company-users.svg?react"
import ContentLibraryIcon from "~/images/icons/content-library.svg?react"
import CreativesIcon from "~/images/icons/creatives.svg?react"
import CreatorBrandsIcon from "~/images/icons/creator-brands.svg?react"
import DashboardIcon from "~/images/icons/dashboard.svg?react"
import MessagesIcon from "~/images/icons/messages.svg?react"
import ProductsIcon from "~/images/icons/products.svg?react"
import ReportsIcon from "~/images/icons/reports.svg?react"
import UsersIcon from "~/images/icons/users.svg?react"
import poweredBy from "~/images/powered-by"
import workweekPartnerPlatformLogo from "~/images/workweek-partner-platform-logo"
import { CompanyRole, Role } from "../__generated__/graphql"

const SidebarLink = ({
  title,
  icon,
  path,
  showIndicator,
}: {
  title: string
  icon: ReactNode
  path: string
  showIndicator?: boolean
}) => {
  const { pathname } = useLocation()

  const isActive = pathname.startsWith(path)

  return (
    <li>
      <Link
        className={clsx(
          "text-xs-plus tracking-tight px-5 py-1 flex items-center gap-2 rounded cursor-pointer group",
          "hover:bg-foreground hover:text-white",
          {
            "bg-foreground text-white stroke-white": isActive,
            "text-foreground": !isActive,
          }
        )}
        to={path}
      >
        {icon}
        {title}

        {showIndicator && (
          <div
            className={clsx(
              "w-2 h-2 bg-foreground rounded-full group-hover:bg-white",
              { "bg-white": isActive }
            )}
          ></div>
        )}
      </Link>
    </li>
  )
}

interface LinkOption {
  key: string
  title: string
  icon: ReactNode
  path: string
  roles: (Role | CompanyRole)[]
}

export const SidebarLinks = () => {
  const { viewer } = useViewer()
  const { company, companyRole } = useCompanyMaybe()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const isClientAndCompanyAdmin =
    viewer.role === Role.Client && companyRole === CompanyRole.Admin

  const incompleteCompanies = viewer.incompleteCompanies.edges.filter(
    (edge) => edge.role === CompanyRole.Admin
  )
  const isCompanyProfileEditPath = pathname.endsWith("/profile/edit")

  useEffect(() => {
    if (incompleteCompanies.length > 0 && !isCompanyProfileEditPath) {
      navigate(
        paths.companyProfileEditPath({
          slug: incompleteCompanies[0].node.slug,
        })
      )
    }
  }, [incompleteCompanies, isCompanyProfileEditPath, navigate, viewer.role])
  if (!viewer || !viewer.role) return null

  const companySlug =
    company?.slug ?? viewer.companies.edges[0]?.node.slug ?? "company-missing"

  const linkOptions: LinkOption[] = [
    {
      key: "dashboard-workweek",
      title: "Dashboard",
      icon: <DashboardIcon />,
      path: paths.dashboardPath({ slug: companySlug }),
      roles: [Role.WorkweekTeam, Role.WorkweekAdmin, Role.Creative],
    },
    {
      key: "dashboard-clients",
      title: "Dashboard",
      icon: <DashboardIcon />,
      path: paths.companyDashboardPath({ slug: companySlug }),
      roles: [Role.Client, CompanyRole.Admin],
    },
    {
      key: "messages",
      title: "Messages",
      icon: <MessagesIcon className="w-4 h-4" />,
      path: paths.messagesPath({ slug: companySlug }),
      roles: [
        Role.WorkweekTeam,
        Role.WorkweekAdmin,
        Role.Client,
        CompanyRole.Admin,
      ],
    },
    {
      key: "campaigns",
      title: "Campaigns",
      icon: <CampaignsIcon />,
      path: paths.companyCampaignsPath({ slug: companySlug }),
      roles: [Role.Client],
    },
    {
      key: "allCompanies",
      title: "Campaigns",
      icon: <CampaignsIcon />,
      path: paths.campaignsPath.pattern,
      roles: [Role.WorkweekTeam, Role.WorkweekAdmin, Role.Creative],
    },
    {
      key: "products",
      title: "Products",
      icon: <ProductsIcon />,
      path: paths.companyProductsPath({ slug: companySlug }),
      roles: [
        Role.WorkweekTeam,
        Role.WorkweekAdmin,
        Role.Client,
        Role.Creative,
        CompanyRole.Admin,
      ],
    },
    {
      key: "reports",
      title: "Reports",
      icon: <ReportsIcon />,
      path: paths.companyReportsPath({ slug: companySlug }),
      roles: [
        Role.WorkweekTeam,
        Role.WorkweekAdmin,
        Role.Client,
        Role.Creative,
        CompanyRole.Admin,
      ],
    },
    {
      key: "content-library",
      title: "Content Library",
      icon: <ContentLibraryIcon />,
      path: paths.companyContentLibraryPath({ slug: companySlug }),
      roles: [
        Role.WorkweekTeam,
        Role.WorkweekAdmin,
        Role.Client,
        Role.Creative,
        CompanyRole.Admin,
      ],
    },
    {
      key: "companies",
      title: "Companies",
      icon: <CompaniesIcon />,
      path: paths.companiesPath({ slug: companySlug }),
      roles: [Role.WorkweekTeam, Role.WorkweekAdmin],
    },
    {
      key: "clients",
      title: "Clients",
      icon: <ClientsIcon />,
      path: paths.companyClientsPath({ slug: companySlug }),
      roles: [Role.WorkweekTeam],
    },
    {
      key: "creatives",
      title: "Creatives",
      icon: <CreativesIcon />,
      path: paths.companyCreativesPath({ slug: companySlug }),
      roles: [Role.WorkweekTeam],
    },
    {
      key: "users",
      title: "Users",
      icon: <UsersIcon />,
      path: paths.usersPath({}),
      roles: [Role.WorkweekAdmin],
    },
    {
      key: "creator-brands",
      title: "Creator Brands",
      icon: <CreatorBrandsIcon />,
      path: paths.creatorBrandsPath({}),
      roles: [Role.WorkweekAdmin],
    },
    {
      key: "company-users",
      title: "Company Users",
      icon: <CompanyUsersIcon />,
      path: paths.companyUsersPath({ slug: companySlug }),
      roles: [CompanyRole.Admin],
    },
    {
      key: "company-profile",
      title: "Company Profile",
      icon: <CompanyProfileIcon />,
      path: paths.companyProfilePath({ slug: companySlug }),
      roles: [CompanyRole.Admin],
    },
  ]

  const visibleLinksForRole = isClientAndCompanyAdmin
    ? linkOptions.filter(
        (link) =>
          link.roles.includes(CompanyRole.Admin) ||
          link.roles.includes(viewer.role)
      )
    : linkOptions.filter((link) => link.roles.includes(viewer.role))

  return (
    <nav className="mt-5 space-y-1">
      <div className="text-xs font-bold tracking-wide uppercase px-5">
        Navigation
      </div>

      <ul className="list-none space-y-1 flex-col">
        {visibleLinksForRole.map((link) => (
          <SidebarLink
            key={link.key}
            title={link.title}
            path={link.path}
            icon={link.icon}
          />
        ))}
      </ul>
    </nav>
  )
}

export const SidebarLayout = () => {
  const { viewer } = useViewerMaybe()

  if (!viewer) {
    return null
  }

  return (
    <div className="flex-1 grow flex">
      <div className="sidebar flex flex-col justify-between gap-2 w-[235px] bg-[#FCC503] pb-6 px-2 max-h-screen overflow-y-auto sticky top-0">
        <div>
          <Link to={paths.rootPath({})} className="pt-6 flex">
            <img
              {...workweekPartnerPlatformLogo}
              alt="Workweek Partner Platform Logo"
              className="mx-auto w-[160px]"
            />
          </Link>

          <SidebarLinks />
        </div>

        <div className="flex flex-col gap-6">
          <img
            {...poweredBy}
            alt="Powered by Workweek Partner Platform"
            className="mx-auto"
          />

          <div className="flex flex-col gap-2 text-xs text-center">
            <div>
              Copyright © {new Date().getFullYear()} All Rights Reserved.
              <br />
              Workweek Media Inc.
            </div>

            <div>Version 0.0.0 (0.0.0)</div>
          </div>
        </div>
      </div>

      <div className="flex-1 flex flex-col overflow-hidden max-w-full">
        <Outlet />
        <ScrollRestoration />
      </div>
    </div>
  )
}
