import { useMutation, useQuery } from "@apollo/client"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"
import { Params } from "static-path"
import { z } from "zod"
import { gql } from "~/__generated__"
import { CampaignDeliverableType } from "~/__generated__/graphql"
import { toCalendarDate } from "~/common/date-formatting"
import { gqlMatchOptional } from "~/common/gql-match"
import {
  campaignDeliverableEditMetricsPath,
  campaignDeliverablePath,
} from "~/common/paths"
import { TextareaField } from "~/fields/textarea-field"
import { Button } from "~/ui/button"
import { Form } from "~/ui/form"
import { Link } from "~/ui/link"
import { LinkButton } from "~/ui/link-button"
import { Pane, PaneBody } from "~/ui/pane"
import { BrandedArticlesSidebarEditMetrics } from "./metrics/branded-articles/branded-articles-sidebar-edit-metrics"
import { EventsSidebarEditMetrics } from "./metrics/events/events-sidebar-edit-metrics"
import { LeadGenSidebarEditMetrics } from "./metrics/lead-generation/lead-gen-sidebar-edit-metrics"
import { NewsletterAdsSidebarEditMetrics } from "./metrics/newsletters/newsletter-ads-sidebar-edit-metrics"
import { PodcastSidebarEditMetrics } from "./metrics/podcasts/podcast-sidebar-edit-metrics"
import { SocialSidebarEditMetrics } from "./metrics/social/social-sidebar-edit-metrics"

const query = gql(/* GraphQL */ `
  query CampaignDeliverableEditMetricsPaneQuery($id: ID!) {
    node(id: $id) {
      __typename
      ... on CampaignDeliverable {
        id
        deliverableType
        canEditMetrics {
          value
        }

        metricNotes
        recipientsMetric
        opensMetric
        totalClicksMetric
        totalRealClicksMetric
        leadsSuppliedMetric
        leadsToDateMetric
        registrantsMetric
        liveAttendeesMetric
        downloads7DaysPostLaunchMetric
        downloads30DaysPostLaunchMetric
        impressions7DaysPostLaunchMetric
        impressions30DaysPostLaunchMetric
        impressionsMetric
        timeOnSiteMetric
        pageviewsMetric
        dateOfCompletion
      }
    }
  }
`)

export const formSchema = z.object({
  recipientsMetric: z.string().optional(),
  opensMetric: z.string().optional(),
  totalClicksMetric: z.string().optional(),
  totalRealClicksMetric: z.string().optional(),
  manualClicks: z.string().optional(),
  metricNotes: z.string().optional(),
  leadsSuppliedMetric: z.string().optional(),
  leadsToDateMetric: z.string().optional(),
  dateOfCompletion: z.string().optional(),
  registrantsMetric: z.string().optional(),
  liveAttendeesMetric: z.string().optional(),
  downloads7DaysPostLaunchMetric: z.string().optional(),
  downloads30DaysPostLaunchMetric: z.string().optional(),
  impressions7DaysPostLaunchMetric: z.string().optional(),
  impressions30DaysPostLaunchMetric: z.string().optional(),
  impressionsMetric: z.string().optional(),
  pageviewsMetric: z.string().optional(),
  timeOnSiteMetric: z.string().optional(),
})

const SAVE_DELIVERABLE_METRICS = gql(/* GraphQL */ `
  mutation CampaignDeliverableMetricsSave(
    $input: CampaignDeliverableUpdateInput!
  ) {
    campaignDeliveryUpdate(input: $input) {
      campaignDeliverable {
        id
        opensMetric
        recipientsMetric
        totalClicksMetric
        totalRealClicksMetric
        metricNotes

        leadsSuppliedMetric
        leadsToDateMetric
        dateOfCompletion

        registrantsMetric
        liveAttendeesMetric

        downloads7DaysPostLaunchMetric
        downloads30DaysPostLaunchMetric
        impressions7DaysPostLaunchMetric
        impressions30DaysPostLaunchMetric
        impressionsMetric

        timeOnSiteMetric
        pageviewsMetric

        dateOfCompletion
      }
    }
  }
`)

export const CampaignDeliverableEditMetricsPane = () => {
  const navigate = useNavigate()
  const params = useParams() as Params<
    typeof campaignDeliverableEditMetricsPath.pattern
  >

  const result = useQuery(query, {
    variables: {
      id: params.deliverableId,
    },
  })

  const deliverable = gqlMatchOptional(result.data?.node, "CampaignDeliverable")

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    values: {
      recipientsMetric: deliverable?.recipientsMetric?.toString() ?? "0",
      opensMetric: deliverable?.opensMetric?.toString() ?? "0",
      totalClicksMetric: deliverable?.totalClicksMetric?.toString() ?? "0",
      totalRealClicksMetric:
        deliverable?.totalRealClicksMetric?.toString() ?? "0",
      // manualClicks: deliverable?.manualClicks.toString() ?? "0",
      leadsSuppliedMetric: deliverable?.leadsSuppliedMetric?.toString() ?? "0",
      leadsToDateMetric: deliverable?.leadsToDateMetric?.toString() ?? "0",
      dateOfCompletion: deliverable?.dateOfCompletion
        ? toCalendarDate(new Date(deliverable.dateOfCompletion)).toISOString()
        : undefined,
      downloads7DaysPostLaunchMetric:
        deliverable?.downloads7DaysPostLaunchMetric?.toString() ?? "0",
      downloads30DaysPostLaunchMetric:
        deliverable?.downloads30DaysPostLaunchMetric?.toString() ?? "0",
      impressions7DaysPostLaunchMetric:
        deliverable?.impressions7DaysPostLaunchMetric?.toString() ?? "0",
      impressions30DaysPostLaunchMetric:
        deliverable?.impressions30DaysPostLaunchMetric?.toString() ?? "0",
      impressionsMetric: deliverable?.impressionsMetric?.toString() ?? "0",
      registrantsMetric: deliverable?.registrantsMetric?.toString() ?? "0",
      liveAttendeesMetric: deliverable?.liveAttendeesMetric?.toString() ?? "0",
      pageviewsMetric: deliverable?.pageviewsMetric?.toString() ?? "0",
      timeOnSiteMetric: deliverable?.timeOnSiteMetric ?? "00:00",
      metricNotes: deliverable?.metricNotes ?? "",
    },
  })

  const [saveDeliverableMetrics] = useMutation(SAVE_DELIVERABLE_METRICS)

  if (!deliverable) {
    return null
  }

  const isNewsletter =
    deliverable.deliverableType === CampaignDeliverableType.NewsletterAds ||
    deliverable.deliverableType === CampaignDeliverableType.DeepDives
  const isLeadGeneration =
    deliverable.deliverableType === CampaignDeliverableType.LeadGen
  const isEvents =
    deliverable.deliverableType === CampaignDeliverableType.VirtualEvents ||
    deliverable.deliverableType === CampaignDeliverableType.LiveEvents
  const isPodcasts =
    deliverable.deliverableType === CampaignDeliverableType.Podcasts
  const isBrandedArticles =
    deliverable.deliverableType === CampaignDeliverableType.BrandedArticles
  const isSocialPosts =
    deliverable.deliverableType === CampaignDeliverableType.SocialPosts

  const valueIfDifferent = (field: keyof z.infer<typeof formSchema>) => {
    if (form.formState.defaultValues?.[field] !== form.getValues(field)) {
      return parseInt(form.getValues(field) ?? "0")
    }
    return undefined
  }

  const onMetricsSubmit = async (data: z.infer<typeof formSchema>) => {
    const result = await saveDeliverableMetrics({
      variables: {
        input: {
          id: deliverable.id,
          deliverableInput: {
            metricNotes: data.metricNotes ?? "",
            ...(isNewsletter && {
              recipientsMetricOverride: valueIfDifferent("recipientsMetric"),
              opensMetricOverride: valueIfDifferent("opensMetric"),
              totalClicksMetricOverride: valueIfDifferent("totalClicksMetric"),
            }),
            ...(isLeadGeneration && {
              leadsSuppliedMetric: parseInt(data.leadsSuppliedMetric ?? "0"),
              leadsToDateMetric: parseInt(data.leadsToDateMetric ?? "0"),
              dateOfCompletion: data.dateOfCompletion
                ? toCalendarDate(new Date(data.dateOfCompletion)).toISOString()
                : undefined,
            }),
            ...(isEvents && {
              registrantsMetric: parseInt(data.registrantsMetric ?? "0"),
              liveAttendeesMetric: parseInt(data.liveAttendeesMetric ?? "0"),
              opensMetricOverride: parseInt(data.opensMetric ?? "0"),
              totalClicksMetricOverride: parseInt(
                data.totalClicksMetric ?? "0"
              ),
            }),
            ...(isPodcasts && {
              downloads7DaysPostLaunchMetric: parseInt(
                data.downloads7DaysPostLaunchMetric ?? "0"
              ),
              downloads30DaysPostLaunchMetric: parseInt(
                data.downloads30DaysPostLaunchMetric ?? "0"
              ),
              impressions7DaysPostLaunchMetric: parseInt(
                data.impressions7DaysPostLaunchMetric ?? "0"
              ),
              impressions30DaysPostLaunchMetric: parseInt(
                data.impressions30DaysPostLaunchMetric ?? "0"
              ),
              impressionsMetric: parseInt(data.impressionsMetric ?? "0"),
            }),
            ...(isBrandedArticles && {
              timeOnSiteMetric: data.timeOnSiteMetric?.toString() ?? "00:00",
              pageviewsMetric: parseInt(data.pageviewsMetric ?? "0"),
              totalClicksMetricOverride: parseInt(
                data.totalClicksMetric ?? "0"
              ),
            }),
            ...(isSocialPosts && {
              impressionsMetric: parseInt(data.impressionsMetric ?? "0"),
              totalClicksMetricOverride: parseInt(
                data.totalClicksMetric ?? "0"
              ),
            }),
          },
        },
      },
    })

    if (!result.errors) {
      navigate(
        campaignDeliverablePath({
          campaignId: params.campaignId,
          deliverableId: params.deliverableId,
        })
      )
    }
  }

  if (!deliverable?.canEditMetrics?.value) {
    return (
      <Pane>
        <PaneBody className="pt-4 flex flex-col border-b">
          <h2 className="font-medium text-lg">Edit Metrics</h2>
        </PaneBody>
        <div className="text-center text-sm text-danger py-4">
          You do not have permission to update this deliverable's metrics.
        </div>
      </Pane>
    )
  }

  const renderMetricsForm = () => {
    switch (deliverable?.deliverableType) {
      case CampaignDeliverableType.NewsletterAds:
      case CampaignDeliverableType.DeepDives:
        return <NewsletterAdsSidebarEditMetrics form={form} />
      case CampaignDeliverableType.LeadGen:
        return <LeadGenSidebarEditMetrics form={form} />
      case CampaignDeliverableType.Podcasts:
        return <PodcastSidebarEditMetrics form={form} />
      case CampaignDeliverableType.VirtualEvents:
      case CampaignDeliverableType.LiveEvents:
        return <EventsSidebarEditMetrics form={form} />
      case CampaignDeliverableType.BrandedArticles:
        return <BrandedArticlesSidebarEditMetrics form={form} />
      case CampaignDeliverableType.SocialPosts:
        return <SocialSidebarEditMetrics form={form} />
      default:
        return `Unknown deliverable type: ${deliverable?.deliverableType}`
    }
  }

  return (
    <Pane>
      <PaneBody className="pt-4 flex flex-col border-b">
        <Link
          to={campaignDeliverablePath({
            campaignId: params.campaignId,
            deliverableId: params.deliverableId,
          })}
          variant="close-pane"
          className="mb-2"
        >
          Close
        </Link>
        <h2 className="font-medium text-lg">Edit Metrics</h2>
      </PaneBody>
      <PaneBody className="pt-4 flex flex-col">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onMetricsSubmit)}>
            <div className="grid gap-4">
              {renderMetricsForm()}
              <TextareaField
                control={form.control}
                name="metricNotes"
                label="Notes"
                rows={10}
              />
            </div>

            <div className="flex justify-between items-center mt-6">
              <Button
                type="submit"
                disabled={form.formState.isSubmitting}
                className="font-light"
              >
                Save
              </Button>
              <LinkButton
                variant="linkMuted"
                type="button"
                to={campaignDeliverablePath({
                  campaignId: params.campaignId,
                  deliverableId: params.deliverableId,
                })}
                className="text-xs-plus text-gray-400 pr-0"
              >
                Cancel
              </LinkButton>
            </div>
          </form>
        </Form>
      </PaneBody>
    </Pane>
  )
}
