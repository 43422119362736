import { useQuery } from "@apollo/client"
import { zodResolver } from "@hookform/resolvers/zod"
import { useState } from "react"
import { useForm } from "react-hook-form"
import { Form, Link, useOutlet, useParams } from "react-router-dom"
import { Params } from "static-path"
import invariant from "tiny-invariant"
import { z } from "zod"
import { gql } from "~/__generated__"
import {
  CampaignDeliverableStatus,
  CampaignDeliverableType,
} from "~/__generated__/graphql"
import { ScaffoldAdButton } from "~/campaign-deliverables/schedule-button"
import { formatDate, toCalendarDate } from "~/common/date-formatting"
import { gqlMatchOptional } from "~/common/gql-match"
import {
  campaignDeliverableFeedbackPath,
  campaignDeliverableHistoryPath,
  campaignDeliverablePath,
  campaignDeliverableUserPath,
  campaignsPath,
  companyProfilePath,
} from "~/common/paths"
import { useDocumentTitle } from "~/common/use-document-title"
import { useGoBack } from "~/common/use-go-back"
import { useSafeMutation } from "~/common/use-safe-mutation"
import { useValidationErrors } from "~/common/use-validation-errors"
import { CompanyLogo } from "~/companies/company-logo"
import arrowLeft from "~/images/icons/arrow-left"
import editIcon from "~/images/icons/edit"
import feedbackIcon from "~/images/icons/feedback"
import statusHistoryIcon from "~/images/icons/status-history"
import { TablePageLayout } from "~/layouts/table-page-layout"
import { Button } from "~/ui/button"
import { DatePicker } from "~/ui/date-picker"
import { GraphqlError } from "~/ui/errors"
import { ExternalLink } from "~/ui/external-link"
import { FormField } from "~/ui/form"
import { LinkButton } from "~/ui/link-button"
import { Table, TableBody, TableCell, TableHead, TableHeader } from "~/ui/table"
import Text from "~/ui/typography"
import { toast } from "~/ui/use-toast"
import { CampaignDeliverableBriefs } from "./campaign-deliverable-briefs"
import { CampaignDeliverableMetrics } from "./campaign-deliverable-metrics"
import { CampaignDeliverablePublishDate } from "./campaign-deliverable-publish-date"
import { CampaignScheduledPublishedBanner } from "./campaign-scheduled-published-banner"
import { ChangeStatusModal } from "./change-status-modal"
import { DeliverableStatus } from "./deliverable-status"
import { DeliverableType } from "./deliverable-type"
import { Section, SectionLabel } from "./section"
import { CampaignUserSection, CampaignUserSectionProps } from "./user-section"

gql(/* GraphQL */ `
  fragment CampaignDetailPaneUser on User {
    id
    fullName
    role
    ...UserAvatar
  }
`)

gql(/* GraphQL */ `
  fragment CampaignDeliverableDetailFragment on CampaignDeliverable {
    id
    productBriefId
    deliverableName
    trackingUrls
    deliverableType
    placement
    status
    deliverableBriefDueDateCanonical
    publishDateCanonical
    keyDirection
    ctaButton
    ctaUrl
    deliverableContent
    rejectionFeedback
    userIsAssigned
    scheduledForFormatted
    sailthruCampaignUrl
    sailthruCampaignId
    placement
    metricNotes
    ...CampaignDeliverableMetrics
    canUpdateBrief {
      value
    }
    canUpdateDeliverables {
      value
    }
    canApproveOrReject {
      value
    }
    canSchedule {
      value
    }
    canProvideFeedback {
      value
    }
    canEditMetrics {
      value
    }
    canOverrideBriefDueDate {
      value
    }

    productBriefSnapshot {
      id
    }

    creatorBrand {
      id
      creators(first: 100) {
        edges {
          node {
            ...CampaignDetailPaneUser
          }
        }
      }
      creatives(first: 100) {
        edges {
          node {
            ...CampaignDetailPaneUser
          }
        }
      }
    }

    campaign {
      id
      accountManager {
        ...CampaignDetailPaneUser
      }
      client {
        ...CampaignDetailPaneUser
      }
      salesRep {
        ...CampaignDetailPaneUser
      }

      company {
        id
        name
        slug
        website
        description
        ...CompanyLogo
      }
    }
  }
`)

const query = gql(/* GraphQL */ `
  query CampaignDeliverableQuery($id: ID!) {
    node(id: $id) {
      __typename
      ... on CampaignDeliverable {
        ...CampaignDeliverableDetailFragment
      }
    }
  }
`)

const formSchema = z.object({
  deliverableBriefDueDateOverride: z.date().optional(),
})

export type DeliverableFormSchema = z.infer<typeof formSchema>

export const CampaignDeliverableDetailScreen = () => {
  const [changeStatusModalOpen, setChangeStatusModalOpen] = useState(false)
  const params = useParams<Params<typeof campaignDeliverablePath.pattern>>()
  invariant(params.deliverableId, "Expected id param")
  const [runMutation, mutationResult] = useSafeMutation(
    CAMPAIGN_DELIVERABLE_UPDATE_MUTATION
  )
  const result = useQuery(query, { variables: { id: params.deliverableId } })

  const campaignDeliverable = gqlMatchOptional(
    result.data?.node,
    "CampaignDeliverable"
  )
  useDocumentTitle(campaignDeliverable?.deliverableName ?? null)

  const goBack = useGoBack(campaignsPath({}))

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    values: {
      deliverableBriefDueDateOverride:
        campaignDeliverable?.deliverableBriefDueDateCanonical
          ? toCalendarDate(
              new Date(campaignDeliverable.deliverableBriefDueDateCanonical)
            )
          : undefined,
    },
  })

  useValidationErrors(form.setError, mutationResult)

  const outlet = useOutlet()

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    invariant(campaignDeliverable)
    const mutationResult = await runMutation({
      variables: {
        input: {
          id: campaignDeliverable.id,
          deliverableInput: {
            deliverableBriefDueDateOverride:
              values.deliverableBriefDueDateOverride
                ? toCalendarDate(
                    values.deliverableBriefDueDateOverride
                  ).toISOString()
                : undefined,
          },
        },
      },
    })

    if (!mutationResult.errors) {
      toast({
        title: "Success",
        description: "The deliverable Brief Due Date has been updated",
        variant: "default",
      })
    }
  }

  const linkTo: CampaignUserSectionProps["linkTo"] = (user) => {
    invariant(campaignDeliverable)
    return campaignDeliverableUserPath({
      deliverableId: campaignDeliverable.id,
      campaignId: campaignDeliverable.campaign.id,
      userId: user.id,
    })
  }

  if (!campaignDeliverable) {
    return null
  }

  const showScaffoldAdButton =
    campaignDeliverable.canSchedule.value &&
    campaignDeliverable.status === CampaignDeliverableStatus.Approved &&
    (campaignDeliverable.placement === "Primary" ||
      campaignDeliverable.placement === "Secondary" ||
      campaignDeliverable.placement === "Intro")

  return (
    <TablePageLayout padding={false} rightSideSlot={outlet}>
      <div className="flex flex-col flex-1">
        <div className="px-[40px] border-b">
          <Button onClick={goBack} variant="ghost" className="-ms-4">
            <img {...arrowLeft} alt="" className="inline-block me-2" />
            <Text variant="mini-caps">Back</Text>
          </Button>
        </div>

        {result.error ? <GraphqlError error={result.error} /> : null}
        {campaignDeliverable != null ? (
          <div className="flex flex-1 flex-col overflow-auto">
            <div className="grid grid-cols-[minmax(600px,1fr)_475px] p-[40px] gap-[40px] items-start">
              <div className="space-y-12">
                <div className="flex flex-col rounded-lg border border-gray-d0 p-6">
                  <div className="flex justify-between mb-8">
                    <div className="flex items-center gap-4">
                      <CompanyLogo
                        company={campaignDeliverable.campaign.company}
                        size="32"
                      />
                      <div className="text-2xl font-medium">
                        {campaignDeliverable.deliverableName}
                      </div>
                    </div>
                  </div>

                  <CampaignScheduledPublishedBanner
                    campaignDeliverable={campaignDeliverable}
                  />

                  <Table
                    layout="fixed"
                    variant="roundedTopBottom"
                    className="mb-6"
                  >
                    <TableHeader>
                      <tr className="bg-gray-f9">
                        <TableHead>Status</TableHead>
                        <TableHead className="pl-3">Brief Due Date</TableHead>
                        <TableHead>Deliverable Type</TableHead>
                        <TableHead>Placement</TableHead>
                        <TableHead>Publish Date</TableHead>
                      </tr>
                    </TableHeader>
                    <TableBody>
                      <tr key={campaignDeliverable.id}>
                        <TableCell
                          className="pe-2 first:rounded-l-none"
                          spacing="4"
                        >
                          <div className="flex items-center">
                            <Text variant="body-12">
                              <DeliverableStatus
                                className="justify-start"
                                {...campaignDeliverable}
                                status={campaignDeliverable.status}
                              />
                            </Text>
                            {campaignDeliverable.deliverableType !==
                              CampaignDeliverableType.NewsletterAds &&
                              [
                                CampaignDeliverableStatus.Approved,
                                CampaignDeliverableStatus.AdScaffolded,
                                CampaignDeliverableStatus.Scheduled,
                                CampaignDeliverableStatus.Published,
                              ].includes(campaignDeliverable.status) && (
                                <Button
                                  variant="ghost"
                                  size="xs"
                                  className="ms-1"
                                  onClick={() => setChangeStatusModalOpen(true)}
                                >
                                  <img
                                    {...editIcon}
                                    alt=""
                                    className="inline-block align-baseline"
                                  />
                                </Button>
                              )}
                          </div>
                        </TableCell>
                        <TableCell spacing="4" variant="collapse">
                          <Form {...form}>
                            <Text variant="body-12">
                              {campaignDeliverable.canOverrideBriefDueDate
                                .value && (
                                <FormField
                                  control={form.control}
                                  name="deliverableBriefDueDateOverride"
                                  render={({ field }) => (
                                    <DatePicker
                                      date={field.value}
                                      disabled
                                      onOpenChange={(open) => {
                                        if (
                                          !open &&
                                          form.formState.dirtyFields
                                            .deliverableBriefDueDateOverride
                                        ) {
                                          form.handleSubmit(onSubmit)()
                                        }
                                      }}
                                      {...field}
                                    />
                                  )}
                                ></FormField>
                              )}
                              {!campaignDeliverable.canOverrideBriefDueDate
                                .value && (
                                <Text variant="body-12" className="ms-2">
                                  {campaignDeliverable.deliverableBriefDueDateCanonical
                                    ? formatDate(
                                        campaignDeliverable.deliverableBriefDueDateCanonical,
                                        "MMMM d, yyyy"
                                      )
                                    : "N/A"}
                                </Text>
                              )}
                            </Text>
                          </Form>
                        </TableCell>
                        <TableCell spacing="4" variant="collapse">
                          <Text variant="body-12">
                            <DeliverableType {...campaignDeliverable} />
                          </Text>
                        </TableCell>
                        <TableCell spacing="4" variant="collapse">
                          <Text variant="body-12">
                            {campaignDeliverable.placement}
                          </Text>
                        </TableCell>
                        <TableCell
                          spacing="4"
                          variant="collapse"
                          className="text-right pe-4 w-[240px]"
                        >
                          <Text variant="body-12">
                            <CampaignDeliverablePublishDate
                              {...campaignDeliverable}
                            />
                          </Text>
                        </TableCell>
                      </tr>
                    </TableBody>
                  </Table>

                  <ChangeStatusModal
                    onClose={() => setChangeStatusModalOpen(false)}
                    isOpen={changeStatusModalOpen}
                    campaignDeliverable={campaignDeliverable}
                  />

                  <div className="self-start flex gap-2 mb-8">
                    <LinkButton
                      variant="cardControl"
                      size="cardControl"
                      to={campaignDeliverableHistoryPath({
                        campaignId: campaignDeliverable.campaign.id,
                        deliverableId: campaignDeliverable.id,
                      })}
                    >
                      <img {...statusHistoryIcon} alt="" className="me-1" />
                      Status History
                    </LinkButton>
                    {campaignDeliverable.canProvideFeedback.value && (
                      <LinkButton
                        variant="cardControl"
                        size="cardControl"
                        to={campaignDeliverableFeedbackPath({
                          campaignId: campaignDeliverable.campaign.id,
                          deliverableId: campaignDeliverable.id,
                        })}
                      >
                        <img {...feedbackIcon} alt="" className="me-1" />
                        Internal Feedback
                      </LinkButton>
                    )}
                  </div>
                  {showScaffoldAdButton && (
                    <div className="self-start flex gap-2 mb-8">
                      <ScaffoldAdButton
                        campaignDeliverable={campaignDeliverable}
                      />
                    </div>
                  )}

                  <Section className="mb-6 flex-col space-y-4">
                    <SectionLabel underlined>Company Information</SectionLabel>
                    <CompanyDetail
                      label="Company Name"
                      value={
                        <Link
                          to={companyProfilePath({
                            slug: campaignDeliverable.campaign.company.slug,
                          })}
                        >
                          {campaignDeliverable.campaign.company.name}
                        </Link>
                      }
                    />
                    <CompanyDetail
                      label="Company Website"
                      value={
                        campaignDeliverable.campaign.company.website ? (
                          <ExternalLink
                            href={campaignDeliverable.campaign.company.website}
                          >
                            {campaignDeliverable.campaign.company.website}
                          </ExternalLink>
                        ) : (
                          "-"
                        )
                      }
                    />
                    <CompanyDetail
                      label="Company Description"
                      value={
                        <div>
                          {campaignDeliverable.campaign.company.description
                            ? campaignDeliverable.campaign.company.description
                            : "-"}
                        </div>
                      }
                    />
                  </Section>
                </div>

                <CampaignDeliverableBriefs
                  campaignDeliverable={campaignDeliverable}
                />
              </div>
              <div>
                <Section className="grid gap-4">
                  <CampaignDeliverableMetrics
                    campaignDeliverable={campaignDeliverable}
                  />
                  <CampaignUserSection
                    title="Sales Representative"
                    users={
                      campaignDeliverable.campaign.salesRep
                        ? [campaignDeliverable.campaign.salesRep]
                        : []
                    }
                    hideCounter
                    linkTo={linkTo}
                  />

                  <CampaignUserSection
                    title="Account Manager"
                    users={
                      campaignDeliverable.campaign.accountManager
                        ? [campaignDeliverable.campaign.accountManager]
                        : []
                    }
                    hideCounter
                    linkTo={linkTo}
                  />

                  <CampaignUserSection
                    title="Client"
                    users={
                      campaignDeliverable.campaign.client
                        ? [campaignDeliverable.campaign.client]
                        : []
                    }
                    hideCounter
                    linkTo={linkTo}
                  />

                  <CampaignUserSection
                    title="Creatives"
                    users={campaignDeliverable.creatorBrand.creatives.edges.map(
                      (edge) => edge.node
                    )}
                    linkTo={linkTo}
                  />

                  <CampaignUserSection
                    title="Creators"
                    users={campaignDeliverable.creatorBrand.creators.edges.map(
                      (edge) => edge.node
                    )}
                    linkTo={linkTo}
                  />
                </Section>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </TablePageLayout>
  )
}

const CompanyDetail: React.FC<{
  label: string
  value: React.ReactNode
}> = ({ label, value }) => {
  if (!value) return null
  return (
    <div className="grid">
      <Text as="div" variant="body-12" className="text-gray-99">
        {label}
      </Text>
      <Text as="div" variant="body-14">
        {value}
      </Text>
    </div>
  )
}

const CAMPAIGN_DELIVERABLE_UPDATE_MUTATION = gql(/* GraphQL */ `
  mutation updateDeliverable($input: CampaignDeliverableUpdateInput!) {
    campaignDeliveryUpdate(input: $input) {
      campaignDeliverable {
        deliverableBriefDueDate
      }
    }
  }
`)
