import { useLazyQuery, useQuery } from "@apollo/client"
import { zodResolver } from "@hookform/resolvers/zod"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { Link, useOutlet, useParams, useSearchParams } from "react-router-dom"
import { Params } from "static-path"
import invariant from "tiny-invariant"
import { useDebounce } from "use-debounce"
import { z } from "zod"
import { gql } from "~/__generated__"
import {
  CampaignDeliverableStatus,
  ContentLibraryContentReferenceFragmentFragment,
  Role,
} from "~/__generated__/graphql"
import { useViewer } from "~/auth/viewer-context"
import { formatDate } from "~/common/date-formatting"
import * as paths from "~/common/paths"
import { pickNonDefaults } from "~/common/zod-changed-defaults"
import { URLParamsSerializer } from "~/common/zod-search-params"
import { CompanyLogo } from "~/companies/company-logo"
import gridIcon from "~/images/icons/grid"
import medalIcon from "~/images/icons/medal"
import productsIcon from "~/images/icons/products"
import uploadIcon from "~/images/icons/upload"
import workweekW from "~/images/workweek-w"
import { TablePageLayout } from "~/layouts/table-page-layout"
import { ActiveFilters } from "~/search/active-filters"
import { SearchInput } from "~/search/search-input"
import { Button } from "~/ui/button"
import { FilterButton } from "~/ui/filter-button"
import { Form, FormControl, FormField, FormItem, FormLabel } from "~/ui/form"
import { Heading } from "~/ui/heading"
import { Anchor } from "~/ui/link"
import { LinkButton } from "~/ui/link-button"
import Text from "~/ui/typography"
import { DeleteContentReferenceModal } from "./delete-content-reference-modal"

const formSchema = z.object({
  search: z.string(),
  companies: z.array(z.string()),
  products: z.array(z.string()),
  creatorBrands: z.array(z.string()),
})

type FormValues = z.infer<typeof formSchema>

const defaultValues: FormValues = {
  search: "",
  companies: [],
  products: [],
  creatorBrands: [],
}

const urlSerializer = new URLParamsSerializer(formSchema, defaultValues)

gql(/* GraphQL */ `
  fragment ContentLibraryContentReferenceFragment on ContentReference {
    id
    name
    publishDate
    contentReferenceLink
    productBrief {
      id
      name
      company {
        id
        name
        ...CompanyLogo
      }
    }
  }
`)

export const CAMPAIGN_DELIVERABLES_QUERY = gql(/* GraphQL */ `
  query CampaignDeliverablesContentLibraryQuery(
    $statuses: [CampaignDeliverableStatus!]
    $search: String
    $companyIds: [String!]
    $productBriefIds: [ID!]
    $creatorBrandIds: [ID!]
  ) {
    campaignDeliverables(
      filter: {
        statuses: $statuses
        search: $search
        companyIds: $companyIds
        productBriefIds: $productBriefIds
        creatorBrandIds: $creatorBrandIds
      }
    ) {
      edges {
        node {
          id
          deliverableName
          publishDateCanonical
          deliverableType

          productBrief {
            id
            name
            company {
              id
              name
              ...CompanyLogo
            }
          }
        }
      }
    }
  }
`)

export const BEST_IN_CLASS_QUERY = gql(/* GraphQL */ `
  query BestInClassContentReferences(
    $search: String
    $companies: [String!]
    $products: [String!]
  ) {
    contentReferences(
      filters: { search: $search, companies: $companies, products: $products }
      bestInClass: true
    ) {
      edges {
        node {
          ...ContentLibraryContentReferenceFragment
        }
      }
    }
  }
`)

export const WORKWEEK_CONTENT_QUERY = gql(/* GraphQL */ `
  query WorkweekContentQuery($search: String) {
    contentReferences(workweekMarketing: true, filters: { search: $search }) {
      edges {
        node {
          ...ContentLibraryContentReferenceFragment
        }
      }
    }
  }
`)

export const ContentLibraryScreen = () => {
  const { viewer } = useViewer()
  const [searchParams, setSearchParams] = useSearchParams()
  const [isManagingContent, setIsManagingContent] = useState(false)
  const [contentReferenceToDelete, setContentReferenceToDelete] = useState<
    ContentLibraryContentReferenceFragmentFragment | undefined
  >(undefined)

  const outlet = useOutlet()
  const params =
    useParams<Params<typeof paths.companyContentLibraryPath.pattern>>()
  invariant(params.slug, "Expected company slug")

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: formSchema.parse({
      search: searchParams.get("search") ?? "",
      companies: searchParams.getAll("company") ?? [],
      products: searchParams.getAll("product") ?? [],
      creatorBrands: searchParams.getAll("creatorBrand") ?? [],
    }),
  })

  const { watch, setValue } = form

  const formData = watch()

  useEffect(() => {
    const newSearchParams = urlSerializer.serialize(formData)
    if (newSearchParams.toString() !== searchParams.toString()) {
      setSearchParams(newSearchParams, { replace: true })
    }
  }, [formData, searchParams, setSearchParams])

  const nonDefaultValues = pickNonDefaults(formSchema, defaultValues, formData)
  const activeFiltersCount = Object.keys(nonDefaultValues).filter(
    (key) => key !== "search"
  ).length

  const [debouncedSearch] = useDebounce(formData.search, 200)

  const {
    data: currentData,
    previousData,
    loading: loadingCampaignDeliverables,
  } = useQuery(CAMPAIGN_DELIVERABLES_QUERY, {
    variables: {
      statuses: [CampaignDeliverableStatus.Published],
      search: debouncedSearch,
      companyIds: formData.companies,
      productBriefIds: formData.products,
      creatorBrandIds: formData.creatorBrands,
    },
  })

  const data = currentData || previousData
  const campaignDeliverables =
    data?.campaignDeliverables.edges.map((edge) => edge.node) ?? []

  const {
    data: currentWorkweekContentData,
    previousData: previousWorkweekContentData,
    loading: loadingWorkweekContentReferences,
  } = useQuery(WORKWEEK_CONTENT_QUERY, {
    variables: {
      search: debouncedSearch,
    },
  })

  const workweekContentData =
    currentWorkweekContentData || previousWorkweekContentData
  const workweekContentReferences =
    workweekContentData?.contentReferences.edges.map((edge) => edge.node) ?? []

  const {
    data: currentBestInClassData,
    previousData: previousBestInClassData,
    loading: loadingBestInClass,
  } = useQuery(BEST_IN_CLASS_QUERY, {
    variables: {
      search: debouncedSearch,
      companies: formData.companies,
      products: formData.products,
    },
  })

  const bestInClassData = currentBestInClassData || previousBestInClassData
  const bestInClassContentReferences =
    bestInClassData?.contentReferences.edges.map((edge) => edge.node) ?? []

  const [companyQuery, setCompanyQuery] = useState("")
  const [debouncedCompanySearch] = useDebounce(companyQuery, 200)

  const [creatorBrandQuery, setCreatorBrandQuery] = useState("")
  const [debouncedCreatorBrandSearch] = useDebounce(creatorBrandQuery, 200)

  const [
    sendQuery,
    {
      data: currentCompaniesData,
      previousData: previousCompaniesData,
      loading: companiesLoading,
    },
  ] = useLazyQuery(companiesQuery, {
    variables: {
      search: debouncedCompanySearch,
    },
  })

  const companiesData = currentCompaniesData || previousCompaniesData
  const companies =
    companiesData?.companies.edges.map((edge) => edge.node) ?? []

  const [
    sendCreatorBrandQuery,
    {
      data: currentCreatorBrandsData,
      previousData: previousCreatorBrandsData,
      loading: creatorBrandsLoading,
    },
  ] = useLazyQuery(creatorBrandsQuery, {
    variables: {
      search: debouncedCreatorBrandSearch,
    },
  })

  const creatorBrandsData =
    currentCreatorBrandsData || previousCreatorBrandsData
  const creatorBrands =
    creatorBrandsData?.creatorBrands.edges.map((edge) => edge.node) ?? []
  const creatorBrandOptions = creatorBrands.map((creatorBrand) => ({
    label: creatorBrand.name,
    value: creatorBrand.id,
  }))

  const {
    data: filteredCreatorBrandsData,
    previousData: previousFilteredCreatorBrandsData,
  } = useQuery(getCreatorBrandsQuery, {
    variables: {
      ids: formData.creatorBrands,
    },
  })

  const currentFilteredCreatorBrandsData =
    filteredCreatorBrandsData || previousFilteredCreatorBrandsData

  const filteredCreatorBrands =
    currentFilteredCreatorBrandsData?.nodes.map((node) => {
      invariant(node?.__typename === "CreatorBrand")
      return node
    }) ?? []

  const {
    data: filteredCompaniesData,
    previousData: previousFilteredCompaniesData,
  } = useQuery(getCompaniesQuery, {
    variables: {
      ids: formData.companies,
    },
  })

  const currentFilteredCompaniesData =
    filteredCompaniesData || previousFilteredCompaniesData

  const filteredCompanies =
    currentFilteredCompaniesData?.nodes.map((node) => {
      invariant(node?.__typename === "Company")
      return node
    }) ?? []

  const companyNameFromId = (id: string) => {
    const company = filteredCompanies.find((company) => company?.id === id)
    return company?.name
  }

  const companyOptions = companies.map((company) => ({
    label: company.name,
    value: company.id,
  }))

  const [productQuery, setProductQuery] = useState("")
  const [debouncedProductSearch] = useDebounce(productQuery, 200)

  const [
    execProductQuery,
    {
      data: currentProductsData,
      previousData: previousProductsData,
      loading: productsLoading,
    },
  ] = useLazyQuery(productsQuery, {
    variables: {
      search: debouncedProductSearch,
    },
  })

  const productsData = currentProductsData || previousProductsData
  const products =
    productsData?.productBriefs.edges.map((edge) => edge.node) ?? []

  const {
    data: filteredProductsData,
    previousData: previousFilteredProductsData,
  } = useQuery(getProductsQuery, {
    variables: {
      ids: formData.products,
    },
  })

  const currentFilteredProductsData =
    filteredProductsData || previousFilteredProductsData

  const filteredProducts =
    currentFilteredProductsData?.nodes.map((node) => {
      invariant(node?.__typename === "ProductBrief")
      return node
    }) ?? []

  const productNameFromId = (id: string) => {
    const product = filteredProducts.find((product) => product?.id === id)
    return product?.name
  }

  const creatorBrandNameFromId = (id: string) => {
    const creatorBrand = filteredCreatorBrands.find((cb) => cb?.id === id)
    return creatorBrand?.name
  }

  const productOptions = products.map((product) => ({
    label: product.name,
    value: product.id,
  }))

  const isWorkweekAdmin = viewer.role === Role.WorkweekAdmin

  return (
    <TablePageLayout rightSideSlot={outlet}>
      <div className="px-4 space-y-8">
        <div>
          <div className="flex items-center justify-between mb-8">
            <Form {...form}>
              <form className="flex items-center gap-2">
                <FormField
                  control={form.control}
                  name="search"
                  render={({ field, fieldState }) => (
                    <SearchInput placeholder="Search Library" {...field} />
                  )}
                />

                <FormField
                  control={form.control}
                  name="companies"
                  render={({ field }) => (
                    <FormItem className="space-y-0">
                      <FormLabel className="sr-only">
                        Filter by Company
                      </FormLabel>
                      <FormControl>
                        <FilterButton
                          typeAhead
                          isLoading={companiesLoading}
                          text="Filter by Company"
                          onOpen={() => sendQuery()}
                          options={companyOptions}
                          {...field}
                          onQueryChange={(query) => {
                            setCompanyQuery(query)
                          }}
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="products"
                  render={({ field }) => (
                    <FormItem className="space-y-0">
                      <FormLabel className="sr-only">
                        Filter by Product
                      </FormLabel>
                      <FormControl>
                        <FilterButton
                          typeAhead
                          isLoading={productsLoading}
                          text="Filter by Product"
                          onOpen={() => execProductQuery()}
                          options={productOptions}
                          {...field}
                          onQueryChange={(query) => {
                            setProductQuery(query)
                          }}
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="creatorBrands"
                  render={({ field }) => (
                    <FormItem className="space-y-0">
                      <FormLabel className="sr-only">
                        Filter by Creator Brand
                      </FormLabel>
                      <FormControl>
                        <FilterButton
                          typeAhead
                          isLoading={creatorBrandsLoading}
                          text="Filter by Creator Brand"
                          onOpen={() => sendCreatorBrandQuery()}
                          options={creatorBrandOptions}
                          {...field}
                          onQueryChange={(query) => {
                            setCreatorBrandQuery(query)
                          }}
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
              </form>
            </Form>
            {isWorkweekAdmin && (
              <div className="flex items-center gap-2">
                <LinkButton
                  variant="cardControl"
                  size="sm"
                  className="gap-2"
                  to={paths.companyContentLibraryUploadPath({
                    slug: params.slug,
                  })}
                >
                  <img
                    {...uploadIcon}
                    alt=""
                    className="inline-block align-baseline"
                  />
                  <Text variant="body-12">Upload Content</Text>
                </LinkButton>
                <Button
                  variant={isManagingContent ? "outline" : "cardControl"}
                  size="sm"
                  className="gap-2"
                  onClick={() =>
                    setIsManagingContent(
                      (isManagingContent) => !isManagingContent
                    )
                  }
                >
                  <img
                    {...gridIcon}
                    alt=""
                    className="inline-block align-baseline"
                  />
                  <Text variant="body-12">
                    {isManagingContent
                      ? "Stop Managing Content"
                      : "Manage Content"}
                  </Text>
                </Button>
              </div>
            )}
          </div>
          {activeFiltersCount > 0 && (
            <div className="bg-gray-50 rounded-lg p-5 py-4 flex items-center gap-6 mb-8">
              <Heading
                title="Filters applied"
                count={activeFiltersCount}
                className="mb-0"
              />
              {nonDefaultValues.companies &&
                nonDefaultValues.companies.length > 0 && (
                  <ActiveFilters
                    label="Company"
                    values={formData.companies
                      .map((id) => companyNameFromId(id))
                      .join(", ")}
                    onClear={() => setValue("companies", [])}
                  />
                )}
              {nonDefaultValues.products &&
                nonDefaultValues.products.length > 0 && (
                  <ActiveFilters
                    label="Product"
                    values={formData.products
                      .map((id) => productNameFromId(id))
                      .join(", ")}
                    onClear={() => setValue("products", [])}
                  />
                )}
              {nonDefaultValues.creatorBrands &&
                nonDefaultValues.creatorBrands.length > 0 && (
                  <ActiveFilters
                    label="Creator Brand"
                    values={formData.creatorBrands
                      .map((id) => creatorBrandNameFromId(id))
                      .join(", ")}
                    onClear={() => setValue("creatorBrands", [])}
                  />
                )}
            </div>
          )}
          <div>
            <div className="flex-col flex border-b flex-1 pb-4">
              <Text variant="body-10-bold" className="uppercase">
                My Content
              </Text>
            </div>

            <div className="mt-8 flex items-center gap-6 overflow-x-scroll pb-8 -mx-12">
              {!loadingCampaignDeliverables &&
                campaignDeliverables.length === 0 && (
                  <Text variant="body-14" className="text-gray-500 mx-12">
                    No content found
                  </Text>
                )}
              {campaignDeliverables.map((campaignDeliverable) => (
                <Link
                  to={paths.companyContentReferenceDetailPath({
                    slug: params.slug ?? "",
                    campaignDeliverableId: campaignDeliverable.id,
                  })}
                  key={campaignDeliverable.id}
                  className="border w-[300px] h-[200px] border-gray-d0 rounded-lg p-4 flex flex-col justify-between shrink-0 first:ml-12 last:mr-12 hover:border-gray-400/80 hover:shadow-otp"
                >
                  <div>
                    {campaignDeliverable.productBrief && (
                      <div className="flex items-center gap-2">
                        <CompanyLogo
                          size="24"
                          company={campaignDeliverable.productBrief.company}
                        />
                        <Text variant="body-14-medium">
                          {campaignDeliverable.productBrief.company.name}
                        </Text>
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col gap-2">
                    <Text variant="body-14-medium">
                      {campaignDeliverable.deliverableName}
                    </Text>
                    {campaignDeliverable.productBrief && (
                      <div className="flex items-center gap-2">
                        <div className="rounded-full border w-6 h-6 flex items-center justify-center">
                          <img
                            {...productsIcon}
                            alt=""
                            className="inline-block align-baseline w-3 h-3"
                          />
                        </div>
                        <Text variant="body-14-medium">
                          {campaignDeliverable.productBrief.name}
                        </Text>
                      </div>
                    )}
                    <div className="flex items-center justify-between">
                      <Text variant="body-12" className="text-gray-500">
                        {campaignDeliverable.publishDateCanonical
                          ? formatDate(
                              campaignDeliverable.publishDateCanonical,
                              "MMM d, yyyy"
                            )
                          : "-"}
                      </Text>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
            <div className="flex items-center gap-2 border-b flex-1 pb-4 mt-8">
              <Text variant="body-10-bold" className="uppercase">
                Best In Class
              </Text>
              <img {...medalIcon} alt="" className="w-4 h-4" />
            </div>

            <div className="mt-8 flex items-center gap-6 overflow-x-scroll pb-8 -mx-12">
              {!loadingBestInClass &&
                bestInClassContentReferences.length === 0 && (
                  <Text variant="body-14" className="text-gray-500 mx-12">
                    No Best in Class found
                  </Text>
                )}
              {bestInClassContentReferences.map((contentReference) => (
                <Anchor
                  key={contentReference.id}
                  className="relative border w-[300px] h-[200px] border-gray-d0 rounded-lg p-4 flex flex-col justify-between shrink-0 first:ml-12 last:mr-12 hover:border-gray-400/80 hover:shadow-otp no-underline"
                  target="_blank"
                  href={contentReference.contentReferenceLink}
                >
                  <div>
                    {isManagingContent && (
                      <DeleteButton
                        onClick={(e) => {
                          e.preventDefault()
                          setContentReferenceToDelete(contentReference)
                          setIsManagingContent(false)
                        }}
                      />
                    )}
                    {contentReference.productBrief && (
                      <div className="flex items-center gap-2">
                        <CompanyLogo
                          size="24"
                          company={contentReference.productBrief.company}
                        />
                        <Text variant="body-14-medium">
                          {contentReference.productBrief.company.name}
                        </Text>
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col gap-2">
                    <Text variant="body-14-medium">
                      {contentReference.name}
                    </Text>
                    {contentReference.productBrief && (
                      <div className="flex items-center gap-2">
                        <div className="rounded-full border w-6 h-6 flex items-center justify-center">
                          <img
                            {...productsIcon}
                            alt=""
                            className="inline-block align-baseline w-3 h-3"
                          />
                        </div>
                        <Text variant="body-14-medium">
                          {contentReference.productBrief.name}
                        </Text>
                      </div>
                    )}
                    <div className="flex items-center justify-between">
                      <Text variant="body-12" className="text-gray-500">
                        {formatDate(
                          contentReference.publishDate,
                          "MMM d, yyyy"
                        )}
                      </Text>
                      <img {...medalIcon} alt="" className="w-4 h-4" />
                    </div>
                  </div>
                </Anchor>
              ))}
            </div>

            <div className="flex items-center gap-2 border-b flex-1 pb-4 mt-8 justify-between">
              <Text variant="body-10-bold" className="uppercase">
                Workweek Content
              </Text>
              {isWorkweekAdmin && (
                <LinkButton
                  variant="linkMuted"
                  size="sm"
                  className="uppercase p-0 m-0 h-4 font-medium"
                  to={paths.companyContentLibraryWorkweekUploadPath({
                    slug: params.slug,
                  })}
                >
                  <Text variant="body-10-medium">Upload Workweek Content</Text>
                </LinkButton>
              )}
            </div>
            <div className="mt-8 flex items-center gap-6 overflow-x-scroll pb-8 -mx-12">
              {!loadingWorkweekContentReferences &&
                workweekContentReferences.length === 0 && (
                  <Text variant="body-14" className="text-gray-500 mx-12">
                    No Workweek Content found
                  </Text>
                )}
              {workweekContentReferences.map((contentReference) => (
                <Anchor
                  key={contentReference.id}
                  className="relative border w-[300px] h-[200px] border-gray-d0 rounded-lg p-4 flex flex-col justify-between shrink-0 first:ml-12 last:mr-12 hover:border-gray-400/80 hover:shadow-otp no-underline"
                  target="_blank"
                  href={contentReference.contentReferenceLink}
                >
                  <div>
                    {isManagingContent && isWorkweekAdmin && (
                      <DeleteButton
                        onClick={(e) => {
                          e.preventDefault()
                          setContentReferenceToDelete(contentReference)
                          setIsManagingContent(false)
                        }}
                      />
                    )}
                    <div className="flex items-center gap-2">
                      <div className="bg-black rounded-full w-[24px] h-[24px] flex items-center justify-center">
                        <img {...workweekW} alt="" className="w-4 h-4" />
                      </div>
                      <Text variant="body-14-medium">Workweek</Text>
                    </div>
                  </div>
                  <div className="flex flex-col gap-2">
                    <Text variant="body-14-medium">
                      {contentReference.name}
                    </Text>
                    <div className="flex items-center justify-between">
                      <Text variant="body-12" className="text-gray-500">
                        {formatDate(
                          contentReference.publishDate,
                          "MMM d, yyyy"
                        )}
                      </Text>
                    </div>
                  </div>
                </Anchor>
              ))}
            </div>
          </div>
        </div>
      </div>
      <DeleteContentReferenceModal
        onClose={() => {
          setContentReferenceToDelete(undefined)
        }}
        isOpen={contentReferenceToDelete !== undefined}
        contentReference={contentReferenceToDelete}
      />
    </TablePageLayout>
  )
}

const creatorBrandsQuery = gql(/* GraphQL */ `
  query CreatorBrands($search: String, $first: Int = 10) {
    creatorBrands(filters: { search: $search }, first: $first) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`)

const getCreatorBrandsQuery = gql(/* GraphQL */ `
  query GetCreatorBrands($ids: [ID!]) {
    nodes(ids: $ids) {
      ... on CreatorBrand {
        id
        name
      }
    }
  }
`)

const companiesQuery = gql(/* GraphQL */ `
  query Companies($search: String, $first: Int = 10) {
    companies(search: $search, first: $first) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`)

const getCompaniesQuery = gql(/* GraphQL */ `
  query GetCompanies($ids: [ID!]) {
    nodes(ids: $ids) {
      ... on Company {
        id
        name
      }
    }
  }
`)

const productsQuery = gql(/* GraphQL */ `
  query Products($search: String, $first: Int = 10) {
    productBriefs(filter: { search: $search }, first: $first) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`)

const getProductsQuery = gql(/* GraphQL */ `
  query GetProducts($ids: [ID!]) {
    nodes(ids: $ids) {
      ... on ProductBrief {
        id
        name
      }
    }
  }
`)

const DeleteButton = ({
  onClick,
}: {
  onClick: (e: React.MouseEvent) => void
}) => {
  return (
    <Button
      variant="destructive"
      size="xs"
      className="rounded-full absolute top-2 right-2 px-2 py-1"
      onClick={onClick}
    >
      <Text variant="body-12">Delete</Text>
    </Button>
  )
}
